<script setup lang="ts">
import { useLayoutStoreSearch } from '~/composables/useLayoutStoreSearch';

withDefaults(
    defineProps<{
        type?: string;
    }>(),
    {
        type: '',
    },
);

const searchInput: Ref<HTMLInputElement | null> = ref(null);

const { typingQuery, active, showSuggest, performSuggestSearch, cancelSearch, resetSearch, MIN_ALLOWED_SEARCH_LENGTH } =
    useLayoutStoreSearch();

const { trackViewSearchResults } = useSearchResultsTracking();

const searchContainer = ref(null);

const router = useRouter();
const localePath = useLocalePath();

const openSearch = () => {
    cancelSearch();

    if (typingQuery.value?.length < MIN_ALLOWED_SEARCH_LENGTH) return;

    router.push(localePath('/search?query=' + typingQuery.value));
    trackViewSearchResults(typingQuery.value, 'enter result page');
    active.value = false;
    searchInput.value?.blur();
    resetSearch();
};

const onClear = () => {
    typingQuery.value = '';
    searchInput.value?.focus();
};

// scroll lock if search is open
useHead({
    bodyAttrs: {
        class: computed(() => (showSuggest.value ? 'overflow-hidden' : '')),
    },
});
</script>

<template>
    <div
        ref="searchContainer"
        class="w-full items-center"
        :class="{
            'hidden lg:flex lg:w-1/3 xl:w-1/4 grow-0': type === 'desktop',
            'w-full lg:hidden': type === 'mobile',
        }"
    >
        <div class="flex w-full">
            <SharedInput
                :id="`search-input-${type}`"
                ref="searchInput"
                v-model="typingQuery"
                :data-testid="`search-input-${type}`"
                class="rounded-l-[54px] w-full"
                :label="$t('searchFieldPlaceholder')"
                :show-label="false"
                name="search"
                type="text"
                :placeholder="$t('searchFieldPlaceholder')"
                enterkeyhint="search"
                @click="active = true"
                @input="performSuggestSearch"
                @keyup.enter="openSearch"
                @keyup.esc="active = false"
            >
                <template
                    v-if="typingQuery?.length > 0"
                    #append
                >
                    <SharedButton
                        class="!px-3 bg-transparent border-0"
                        @click="onClear"
                    >
                        <SvgoXMark class="w-3 h-auto text-gray-300 hover:text-brand-primary transition-colors" />
                    </SharedButton>
                </template>
            </SharedInput>
            <SharedButton
                class="!px-5 rounded-l-none rounded-r-[54px]"
                :color="'brand-primary'"
                :title="$t('searchFieldButtonTitle')"
                @click="openSearch"
            >
                <SvgoMagnifyingGlass class="w-auto h-5" />
            </SharedButton>
        </div>
        <Transition
            enter-active-class="transition origin-top duration-300"
            enter-from-class="opacity-0 scale-y-0"
            enter-to-class="opacity-100 scale-y-100"
            leave-active-class="transition origin-top duration-150"
            leave-from-class="opacity-100 scale-y-100"
            leave-to-class="opacity-0 scale-y-0"
        >
            <ProductSearchPopOver
                v-if="showSuggest"
                :type="type"
            />
        </Transition>
    </div>
</template>

<style lang="scss" scoped>
:deep(em) {
    @apply bg-brand-cart;
    @apply not-italic;
}
</style>
